@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@500&display=swap");
ins, mark {
  color: #000;
  background-color: #ff9;
}

.clear, hr {
  clear: both;
}

a, hr, img {
  padding: 0;
  margin: 0;
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  line-height: 100%;
  letter-spacing: 1px;
}

body {
  line-height: 100%;
  font-family: "Avenir Next", Verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
  content: "";
  content: none;
}

a {
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}

ins {
  text-decoration: none;
}

mark {
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.t-left {
  text-align: left !important;
}

.t-center {
  text-align: center !important;
}

.t-right {
  text-align: right !important;
}

.f-left {
  float: left !important;
}

.f-right {
  float: right !important;
}

hr {
  border: none;
}

a img:hover {
  -moz-opacity: 0.7;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

body {
  background: url(../images/bg-main.jpg) repeat top left;
}

body,
p,
ul,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
address {
  color: #333;
  font: 400 normal 16px/166% "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Verdana, "メイリオ", Meiryo, Osaka, "Avenir Next", "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 1px;
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  margin: 0;
}

a {
  color: #e38f35;
  text-decoration: underline;
  transition: all 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.t_left {
  text-align: left !important;
}

.t_right {
  text-align: right !important;
}

.t_center {
  text-align: center !important;
}

@media all and (max-width: 599px) {
  .view_pc {
    display: none;
  }
}

.view_sp {
  display: none;
}
@media all and (max-width: 599px) {
  .view_sp {
    display: block;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.container {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
}
@media all and (max-width: 820px) {
  .container {
    padding: 0 15px;
  }
}

.header {
  background: url(../images/bg-head.png) repeat-x top -20px left;
}
@media all and (max-width: 820px) {
  .header {
    margin-bottom: 20px;
  }
  .header__logo {
    width: 160px;
  }
}
.header__inner {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  gap: 10px;
  padding: 15px 0;
}
@media all and (max-width: 820px) {
  .header__inner {
    padding: 15px;
  }
}
.header__menu {
  gap: 0 16px;
}
.header__menu a {
  display: block;
  height: 110px;
  overflow: hidden;
}
.header__menu a:hover {
  opacity: 1;
}
.header__menu a:hover img {
  opacity: 1;
  margin-top: -110px;
}
.header__info {
  width: 278px;
}
.header__info-txt {
  font-size: 13px;
  margin-bottom: 4px;
  text-align: center;
}
.header__tel {
  margin-bottom: 5px;
}
.header__time {
  font-size: 13px;
  text-align: center;
}
.header__nav {
  margin: -10px 0 10px;
  animation-duration: 0.1s;
}
@media all and (max-width: 820px) {
  .header__nav {
    display: none;
    background-color: rgba(194, 163, 100, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    z-index: 1000;
  }
}
.header__nav-inner {
  background: url(../images/bg-nav.png) no-repeat center center;
  background-size: cover;
  width: 1040px;
  height: 68px;
  gap: 20px 30px;
  -webkit-justify-content: center;
  justify-content: center;
}
@media all and (max-width: 820px) {
  .header__nav-inner {
    background: none;
  }
}
.header .menu-item a {
  color: #333;
  font-family: "Kiwi Maru", serif;
  font-size: 18px;
  letter-spacing: 0;
  text-decoration: none;
  text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff;
  transition: all 0.2s;
}
.header .menu-item a:hover {
  opacity: 1;
  background: -webkit-linear-gradient(transparent 80%, #e38f35 80%);
  background: -o-linear-gradient(transparent 80%, #e38f35 80%);
  background: linear-gradient(transparent 80%, #e38f35 80%);
}
.header .menu-item.current-menu-item a {
  background: -webkit-linear-gradient(transparent 80%, #e38f35 80%);
  background: -o-linear-gradient(transparent 80%, #e38f35 80%);
  background: linear-gradient(transparent 80%, #e38f35 80%);
}

#menuButton {
  display: block;
  height: 40px;
  position: relative;
  z-index: 1010;
  border: solid 2px #333;
  border-radius: 3px;
  text-decoration: none;
  color: #333;
  font: 700 16px/38px arial, Verdana;
  padding: 0 40px 0 10px;
  outline: none;
}
#menuButton:hover {
  opacity: 1;
}
#menuButton span {
  display: block;
  background: #333;
  width: 24px;
  height: 2px;
  border-radius: 2px;
  position: absolute;
  right: 9px;
  transition: all 0.4s;
}
#menuButton span:first-child {
  top: 10px;
}
#menuButton span:nth-child(2) {
  margin-top: -1px;
  top: 50%;
}
#menuButton span:last-child {
  bottom: 10px;
}
#menuButton.active {
  color: #fff;
  border-color: #fff;
}
#menuButton.active span {
  background: #fff;
}
#menuButton.active span:first-child {
  -webkit-transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
  top: 9px;
}
#menuButton.active span:nth-child(2) {
  opacity: 0;
}
#menuButton.active span:last-child {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -moz-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
  bottom: 9px;
}

#pagetop_button {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 9999;
}
@media all and (max-width: 1040px) {
  #pagetop_button {
    display: none !important;
  }
}
@media all and (max-width: 599px) {
  #pagetop_button {
    display: none !important;
  }
}

.footer {
  background: url(../images/bg-foot.png) repeat-x left top;
  padding: 100px 0 30px;
}
.footer__inner {
  -webkit-justify-content: center;
  justify-content: center;
}
.footer__left {
  width: 260px;
}
@media all and (max-width: 599px) {
  .footer__left {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}
.footer__left img {
  width: 185px;
  margin-bottom: 16px;
}
.footer__center {
  background-color: #eae7e3;
  border-radius: 8px;
  padding: 20px;
  width: 240px;
}
@media all and (max-width: 599px) {
  .footer__center {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}
.footer__center h3 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 8px;
}
@media all and (max-width: 599px) {
  .footer__center h3 {
    width: 100%;
  }
}
.footer__center ul {
  gap: 5px 10px;
}
@media all and (max-width: 599px) {
  .footer__center ul {
    width: 150px;
  }
}
.footer__center li {
  font-size: 13px;
}
.footer__address {
  font-family: "Kiwi Maru", serif;
  line-height: 160%;
  margin-bottom: 20px;
}
.footer__address a {
  color: #000;
}
.footer__btn-contact {
  max-width: 240px;
  margin-bottom: 20px;
}
@media all and (max-width: 599px) {
  .footer__btn-contact {
    max-width: 100%;
  }
}
.footer__btn-contact a {
  color: #fff;
  display: block;
  text-decoration: none;
  border-radius: 10px;
  font: 500 18px/140% "Kiwi Maru", serif;
  padding: 15px 30px 15px 15px;
  position: relative;
  background: -webkit-gradient(linear, left top, right bottom, from(#ea6626), to(#e38f35));
  background: -webkit-linear-gradient(left top, #ea6626, #e38f35);
  background: -moz-linear-gradient(left top, #ea6626, #e38f35);
  background: linear-gradient(to right bottom, #ea6626, #e38f35);
}
.footer__btn-contact a:after {
  content: "";
  background: url(../images/arrow01.png) no-repeat left center;
  background-size: 22px;
  width: 22px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: calc(50% - 11px);
}
@media all and (max-width: 599px) {
  .footer__btn-contact a:after {
    background-size: 11px;
    width: 11px;
  }
}
@media all and (max-width: 599px) {
  .footer__btn-contact a {
    text-align: center;
  }
}
.footer__google {
  font-size: 12px;
  padding-right: 10px;
}
@media all and (max-width: 599px) {
  .footer__google {
    padding: 0;
  }
}
.footer__houseoftheyear img {
  width: 190px;
}
@media all and (max-width: 599px) {
  .footer__houseoftheyear {
    width: calc(100% - 150px);
    text-align: center;
  }
  .footer__houseoftheyear img {
    width: 110px;
  }
}
.footer__right {
  width: calc(100% - 500px);
  padding-left: 30px;
}
@media all and (max-width: 599px) {
  .footer__right {
    width: 100%;
    padding: 0;
  }
}
.footer__nav {
  gap: 7px 15px;
  margin: 20px 0;
}
@media all and (max-width: 599px) {
  .footer__nav {
    margin-top: 20px;
  }
}
.footer__nav li {
  font-family: "Kiwi Maru", serif;
  font-size: 15px;
}
.footer__nav a {
  color: #333;
}
.footer__nav a:hover {
  text-decoration: underline;
}
.footer__copy {
  color: #858585;
  font-size: 15px;
  text-align: right;
}
@media all and (max-width: 599px) {
  .footer__copy {
    text-align: center;
  }
}

.fb-page iframe {
  width: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media all and (max-width: 599px) {
  .header {
    margin-bottom: 10px;
  }
}

.breadcrumbs {
  margin-bottom: 25px;
  padding-right: 36px;
}
.breadcrumbs ul {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
@media all and (max-width: 820px) {
  .breadcrumbs ul {
    -webkit-justify-content: start;
    justify-content: start;
  }
}
.breadcrumbs li {
  font-size: 13px;
}
.breadcrumbs li:after {
  content: ">";
  margin: 0 10px;
}
.breadcrumbs li:last-child:after {
  content: "";
  margin: 0;
}

.main {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-bottom: 60px;
}

.article {
  width: calc(100% - 240px);
  padding-left: 30px;
}
@media all and (max-width: 820px) {
  .article {
    width: 100%;
    padding: 0;
    margin-bottom: 50px;
  }
}
.article__heading {
  background: url(../images/bg-h2.png) no-repeat left bottom;
  font-family: "Kiwi Maru", serif;
  font-size: 30px;
  line-height: 140%;
  margin-bottom: 30px;
  padding: 0 20px 10px 5px;
}
@media all and (max-width: 599px) {
  .article__heading {
    font-size: 28px;
    padding: 0 0 10px;
  }
}
.article h2 {
  color: #167b4a;
  font-size: 28px;
  font-weight: bold;
  margin: 1.5rem 0;
}
@media all and (max-width: 599px) {
  .article h2 {
    font: 700 24px/140% "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Verdana, "メイリオ", Meiryo, Osaka, "Avenir Next", "游ゴシック", "Yu Gothic", sans-serif;
  }
}
.article h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0.6rem;
}
@media all and (max-width: 599px) {
  .article h3 {
    font: 700 20px/140% "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Verdana, "メイリオ", Meiryo, Osaka, "Avenir Next", "游ゴシック", "Yu Gothic", sans-serif;
  }
}
.article p {
  margin: 0 0 1rem;
  word-break: break-all;
}
.article ul {
  list-style-type: disc;
  padding-left: 30px;
  margin: 0 0 1rem;
}
.article ul li {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 140%;
}
.article ul li:last-child {
  margin: 0;
}
.article ul.ul-check {
  list-style-type: none;
  padding: 0;
}
.article ul.ul-check li {
  background: url(../images/icon-check.svg) no-repeat left top;
  padding-left: 28px;
}
.article .wp-block-image {
  text-align: center;
}

.aside {
  width: 240px;
}
@media all and (max-width: 820px) {
  .aside {
    width: 100%;
  }
}
.aside__orange {
  background-color: #e38f35;
  border-radius: 8px;
  padding: 12px 10px;
  margin-bottom: 15px;
  gap: 10px;
}
.aside__other {
  -webkit-justify-content: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
}
.aside__bnr {
  text-align: center;
}
@media all and (max-width: 820px) {
  .aside__orange .aside__bnr {
    width: calc(33.3333333333% - 7px);
  }
}
@media all and (max-width: 599px) {
  .aside__bnr {
    width: 100%;
  }
  .aside__orange .aside__bnr {
    width: 100%;
  }
}
.aside__bnr img {
  max-width: 100%;
}
.aside__bnr.zeh a {
  color: #fff;
  display: block;
  text-decoration: none;
  border-radius: 10px;
  font: 500 16px/140% "Kiwi Maru", serif;
  padding: 15px 30px 15px 15px;
  text-align: left;
  position: relative;
  background: -webkit-gradient(linear, left top, right bottom, from(#ea6626), to(#e38f35));
  background: -webkit-linear-gradient(left top, #ea6626, #e38f35);
  background: -moz-linear-gradient(left top, #ea6626, #e38f35);
  background: linear-gradient(to right bottom, #ea6626, #e38f35);
}
@media all and (max-width: 820px) {
  .aside__bnr.zeh a {
    text-align: center;
    font-size: 20px;
  }
  .aside__bnr.zeh a br {
    display: none;
  }
}
@media all and (max-width: 599px) {
  .aside__bnr.zeh a {
    font-size: 18px;
  }
}
.aside__bnr.zeh a:after {
  content: "";
  background: url(../images/arrow01.png) no-repeat left center;
  background-size: 22px;
  width: 22px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: calc(50% - 11px);
}
@media all and (max-width: 599px) {
  .aside__bnr.zeh a:after {
    background-size: 11px;
    width: 11px;
  }
}