// nav
.header {
    @media all and ( $sm ){
        margin-bottom: 10px;
    }
}


// パンくず
.breadcrumbs {
    margin-bottom: 25px;
    padding-right: 36px;

    ul{
        -webkit-justify-content: flex-end;
        justify-content: flex-end;

        @media all and ( $tab ){
            -webkit-justify-content: start;
            justify-content: start;
        }
    }

    li {
        font-size: 13px;

        &:after {
            content: ">";
            margin: 0 10px;
        }

        &:last-child:after {
            content: "";
            margin: 0;
        }
    }
}

// main
.main{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin-bottom: 60px;
}

.article{
    width: calc(100% - 240px);
    padding-left: 30px;

    @media all and ( $tab ){
        width: 100%;
        padding: 0;
        margin-bottom: 50px;
    }

    &__heading{
        background: url(../images/bg-h2.png) no-repeat left bottom;
        font-family: $font_kiwi;
        font-size: 30px;
        line-height: 140%;
        margin-bottom: 30px;
        padding: 0 20px 10px 5px;

        @media all and ( $sm ){
            font-size: 28px;
            padding: 0 0 10px;
        }
    }

    h2{
        color: #167b4a;
        font-size: 28px;
        font-weight: bold;
        margin: 1.5rem 0;

        @media all and ( $sm ){
            font: 700 24px/140% $font_g;
        }
    }

    h3{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: .6rem;

        @media all and ( $sm ){
            font: 700 20px/140% $font_g;
        }
    }

    p{
        margin: 0 0 1rem;
        word-break: break-all
    }

    ul{
        list-style-type: disc;
        padding-left: 30px;
        margin: 0 0 1rem;

        li{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
            line-height: 140%;

            &:last-child{
                margin: 0;
            }
        }

        &.ul-check{
            list-style-type: none;
            padding: 0;

            li{
                background: url(../images/icon-check.svg) no-repeat left top;
                padding-left: 28px;
            }
        }
    }

    .wp-block-image{
        text-align: center;
    }
}

.aside{
    width: 240px;

    @media all and ( $tab ){
        width: 100%;
    }

    &__orange {
        background-color: #e38f35;
        border-radius: 8px;
        padding: 12px 10px;
        margin-bottom: 15px;
        gap: 10px;
    }

    &__other{
        -webkit-justify-content: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 12px;
    }

    &__bnr {
        text-align: center;

        @media all and ( $tab ){
            .aside__orange & {
                width: calc(100% / 3 - 7px);
            }
        }

        @media all and ( $sm ){
            width: 100%;

            .aside__orange & {
                width: 100%;
            }
        }

        img {
            max-width: 100%;
        }

        &.zeh a {
            color: #fff;
            display: block;
            text-decoration: none;
            border-radius: 10px;
            font: 500 16px/140% $font_kiwi;
            padding: 15px 30px 15px 15px;
            text-align: left;
            position: relative;
            background:-webkit-gradient(linear, left top, right bottom, from(#ea6626), to(#e38f35));
            background:-webkit-linear-gradient(left top, #ea6626, #e38f35);
            background:-moz-linear-gradient(left top, #ea6626, #e38f35);
            background:linear-gradient(to right bottom, #ea6626, #e38f35);

            @media all and ( $tab ){
                text-align: center;
                font-size: 20px;

                br{
                    display: none;
                }
            }

            @media all and ( $sm ){
                font-size: 18px;
            }

            &:after{
                content: "";
                background: url(../images/arrow01.png) no-repeat left center;
                background-size: 22px;
                width: 22px;
                height: 20px;
                position: absolute;
                right: 15px;
                top: calc(50% - 11px);

                @media all and ( $sm ){
                    background-size: 11px;
                    width: 11px;
                }
            }
        }
    }
}